import { WindowKeys } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectWindowType } from "@evr/store/slices/window";
import { theme } from "@evr/theme";
import { Flex } from "@evr/ui/FlexBox";

import { DepotWindow } from "./Depot";
import { DriverWindow } from "./Driver";
import { LezWindow } from "./Lez";
import { TableWindowContainer } from "./TableWindowContainer";
import { TablesHeader } from "./TableWindowHeader";
import { VehicleWindow } from "./Vehicle";

export const TablesWindow = () => {
  const windowType = useAppSelector(selectWindowType);

  if (windowType !== WindowKeys.DRIVER && windowType !== WindowKeys.VEHICLE && windowType !== WindowKeys.DEPOT && windowType !== WindowKeys.LEZ) {
    return null;
  }
  return (
    <Flex position="absolute" align="flex-start" inset="0" zIndex={theme.zIndex.window}>
      <TableWindowContainer>
        <TablesHeader />
        {windowType === WindowKeys.DRIVER ? (
          <DriverWindow />
        ) : windowType === WindowKeys.VEHICLE ? (
          <VehicleWindow />
        ) : windowType === WindowKeys.DEPOT ? (
          <DepotWindow />
        ) : 
        (
        <LezWindow />
        )
        }
      </TableWindowContainer>
    </Flex>
  );
};
