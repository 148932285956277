
import { number } from "yup";

import { engineTypeOptions, VehicleKeys, vehicleSizeOptions } from "@evr/constant";
import { SearchFieldKeys, TableColumn } from "@evr/types";


export const vehicleTableColumns: TableColumn<VehicleKeys>[] = [
  {
    name: "Vehicle",
    field: VehicleKeys.ICON,
  },
  {
    name: "Vehicle Size",
    field: VehicleKeys.SIZE,
    sortable: true,
    search: { type: SearchFieldKeys.SELECT, options: vehicleSizeOptions },
  },
  {
    name: "Reg. No.",
    field: VehicleKeys.LICENCE,
    search: { type: SearchFieldKeys.TEXT },
  },
  {
    name: "Make",
    field: VehicleKeys.MAKE,
    sortable: true,
    search: { type: SearchFieldKeys.TEXT },
  },
  {
    name: "Model",
    field: VehicleKeys.MODEL,
    sortable: true,
    search: { type: SearchFieldKeys.TEXT },
  },
  {
    name: "Vehicle Type",
    field: VehicleKeys.ENGINE_TYPE,
    sortable: true,
    search: { type: SearchFieldKeys.SELECT, options: engineTypeOptions },
  },
  {
    name: "Range (Miles)",
    field: VehicleKeys.LISTED_RANGE,
    sortable: true,
  },
  {
    name: "Capacity (KW)",
    field: VehicleKeys.BATTERY_CAPACITY,
    sortable: true,
  },
  {
    name: "Load (Kg)",
    field: VehicleKeys.MAX_PAYLOAD_WEIGHT,
    sortable: true,
  },
  {
    name: "Storage (m3)",
    field: VehicleKeys.STORAGE_SIZE,
    sortable: true,
  },
  {
    name: "LEZ Compliant",
    field: VehicleKeys.LEZ_COMPLIANT
    },
  {
    name: "Created Date",
    field: VehicleKeys.CREATED_DATE,
    sortable: true,
  },
];
