import { useState } from "react";

import { ActiveLayerTypes, Dialogs, WindowKeys } from "@evr/constant";
import { useAppDispatch } from "@evr/hooks/reduxHooks";
import { useApi } from "@evr/hooks/useApi";
import { getAllLiveJourneyAsync } from "@evr/store/slices/all-live-journeys-result/asyncActions";
import { openDialog } from "@evr/store/slices/dialog";
import { setActiveLayer } from "@evr/store/slices/map";
import { openSidebar } from "@evr/store/slices/sidebar";
import { openWindow } from "@evr/store/slices/window";
import { Wrapper } from "@evr/ui/FlexBox";
import { IconButton } from "@evr/ui/IconButton";

import { DrawerBox, Menu } from "./styles";

export const LeftSidebar = () => {
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const api = useApi(getAllLiveJourneyAsync);

  const handleOpenSidebar = () => setOpen(open => !open);
  const handleOpenDepotWindow = () => dispatch(openWindow(WindowKeys.DEPOT));
  const handleOpenDriverWindow = () => dispatch(openWindow(WindowKeys.DRIVER));
  const handleOpenVehicleWindow = () => dispatch(openWindow(WindowKeys.VEHICLE));
  const handleOpenSettingDialog = () => dispatch(openDialog({ type: Dialogs.SETTING }));
  const handleOpenScheduledJourneyWindow = () => dispatch(openWindow(WindowKeys.JOURNEY_SCHEDULED));
  const handleOpenLezWindow = () => dispatch(openWindow(WindowKeys.LEZ));
  const handleInitiateLiveMode = async () => {
    dispatch(setActiveLayer(ActiveLayerTypes.ALL_JOURNEYS));
    await api.sendRequest();
    dispatch(openSidebar());
  };

  const LEZIcon = () => (
    <svg 
      width="36" height="36" 
      viewBox="0 0 64 64" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'block' }}
    >
      <circle 
        cx="32" cy="32" r="28" 
        stroke="#6c757d"
        strokeWidth="4" 
        fill="none" 
      />
      <text 
        x="50%" y="56%" 
        textAnchor="middle"
        fontFamily="Arial, sans-serif"
        fontWeight="bold"
        fontSize="20"
        fill="#6c757d"
      >
        LEZ
      </text>
    </svg>
  );
  
  return (
    <DrawerBox open={open}>
      <Menu>
        <IconButton icon="user" size={1.9} onClick={handleOpenDriverWindow} />
        <IconButton icon="car-alt" size={2.1} onClick={handleOpenVehicleWindow} />
        <IconButton icon="map-marker-alt" size={1.9} onClick={handleOpenDepotWindow} />
        <IconButton icon="fa-solid fa-road" size={1.9} onClick={handleOpenScheduledJourneyWindow} />
        <IconButton customIcon={<LEZIcon />} onClick={handleOpenLezWindow} />
        {/* TODO: Put back in when live mode accepted. */}
        {/* <IconButton title="Live tracking mode" icon="fa-solid fa-route" size={1.9} onClick={handleInitiateLiveMode} /> */}
        <IconButton icon="cog" size={1.9} onClick={handleOpenSettingDialog} />
      </Menu>
      <Wrapper height="45px" width="45px" borderRadius="0 50% 50% 0" position="absolute" left="45px" bgColor="white" />
      <IconButton
        onClick={handleOpenSidebar}
        icon="chevron-right"
        position="absolute"
        left="50px"
        transition="transform 0.3s ease-out"
        transform={`rotate(${open ? "180" : "0"}deg)`}
      />
    </DrawerBox>
  );
};
