import { useState, useEffect, useContext } from "react";

import { Feature } from "ol";
import { FeatureLike } from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import olMapBrowserEvent from "ol/MapBrowserEvent";
import * as olProj from "ol/proj";
import olVectorSource from "ol/source/Vector";
import { Style, Fill, Stroke } from "ol/style";

import { useLezsQuery } from "@evr/apis/lez";
import MapClick from "@evr/components/ReactOL/Interactions/MapClick";
import { Vector } from "@evr/components/ReactOL/Layers";
import MapContext from "@evr/components/ReactOL/Map/MapContext";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectLezsQuery } from "@evr/store/slices/lez";


const LezLayer = () => {
  const map = useContext(MapContext);
  const [polygonSource] = useState(() => new olVectorSource());
  const query = useAppSelector(selectLezsQuery);
  const polygons = useLezsQuery(query).data?.resources.filter(lez => lez.isActive === true).map(lez => ({
    ...lez,
    zone: {
      ...lez.zone,
      coordinates: lez.zone?.coordinates.map(ring =>
        ring.map(coord => olProj.fromLonLat(coord))
      ),
    },
  }));

  const updateVisiblePolygons = () => {
    if (!map || !polygons)
      {
        polygonSource.clear();
        return;
      } 
    const minZoomToDisplay = 7.5;

    const currentZoom = map.getView().getZoom() ?? 0;
    console.log(currentZoom);
    if (currentZoom < minZoomToDisplay) {
      polygonSource.clear();
      return;
    }
    
    const geoJSONFormat = new GeoJSON(); 

    const visibleFeatures = polygons
      
      .map(lez => {
        const feature = new Feature({
          geometry: geoJSONFormat.readGeometry(lez.zone),
        });

        feature.setStyle(
          new Style({
            fill: new Fill({
              color: "rgba(0, 0, 255, 0.3)",
            }),
            stroke: new Stroke({
              color: "#0000ff", 
              width: 2,
            }),
          })
        );
        return feature;
      });

    polygonSource.clear();
    polygonSource.addFeatures(visibleFeatures || []);
  };

  useEffect(() => {
    if (!map) {return;};
    
    updateVisiblePolygons();

    const onMoveEnd = () => updateVisiblePolygons();
    map.on("moveend", onMoveEnd);

    return () => {
      map.un("moveend", onMoveEnd);
    };
  }, [map, polygons, polygonSource]);

  const onPolygonClick = (features: FeatureLike[], e: olMapBrowserEvent<any>) => {
    if (features.length > 0) {
    }
  };

  return (
    <>
      <Vector name="LezLayer" zIndex={1} source={polygonSource} />
      <MapClick
        layerNames={["LezLayer"]}
        active={true}
        onClick={onPolygonClick}
      />
    </>
  );
};

export default LezLayer;
