import { useState } from "react";

import { useEditSettingsMutation } from "@evr/apis/orgSettings";
import { PlanKeys } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectUserOrgSettings, selectUserSubscription } from "@evr/store/slices/auth";
import { CircleLoading } from "@evr/ui/CircleLoading";
import { Flex, RowContainer, Wrapper } from "@evr/ui/FlexBox";
import { ToggleSwitch } from "@evr/ui/ToggleSwitch";
import { Typography } from "@evr/ui/Typography";

export const ToggleAvoidLezs = () => {
  const orgSettings = useAppSelector(selectUserOrgSettings);
  const [avoidLezs] = useState(orgSettings.avoidLezs);


  const [editSpreadWork, { isLoading }] = useEditSettingsMutation();

  const onChange = (status: boolean) => {
    let updatedOrgSettings = { ...orgSettings };
    if (status === true) {
      updatedOrgSettings.prioritiseEVs = false;
    }

    editSpreadWork({ ...updatedOrgSettings, avoidLezs: status });
  };

  return (
    <RowContainer width="100%" justify="flex-start">
      <Typography>Avoid LEZs</Typography>
      <Flex margin="0.2rem 0.2rem 0.2rem auto">
        <ToggleSwitch
          onChange={onChange}
          loading={isLoading}
          value={avoidLezs}
          checked={avoidLezs}
        ></ToggleSwitch>
        <Wrapper position="relative">{isLoading && <CircleLoading size={4} />}</Wrapper>
      </Flex>
    </RowContainer>
  );
};
