import { VehicleKeys } from "@evr/constant";
import { TableColumn } from "@evr/types";

export const vehicleMatchTableColumns: TableColumn<VehicleKeys>[] = [
  {
    name: "Vehicle",
    field: VehicleKeys.ICON,
  },
  {
    name: "Reg. No.",
    field: VehicleKeys.LICENCE,
  },
  {
    name: "Type",
    field: VehicleKeys.ENGINE_TYPE,
  },
  {
    name: "Range (Miles)",
    field: VehicleKeys.LISTED_RANGE,
  },
  {
    name: "Capacity (KW)",
    field: VehicleKeys.BATTERY_CAPACITY,
  },
  {
    name: "Load (Kg)",
    field: VehicleKeys.MAX_PAYLOAD_WEIGHT,
  },
  {
    name: "Storage (m3)",
    field: VehicleKeys.STORAGE_SIZE,
  },
  {
    name: "LEZ Compliant",
    field: VehicleKeys.LEZ_COMPLIANT,
  },
];
