export enum WindowKeys {
  CLOSE = "CLOSE",
  DEPOT = "DEPOT",
  DRIVER = "DRIVER",
  VEHICLE = "VEHICLE",
  PARCEL = "PARCEL",
  DRIVER_VEHICLE = "DRIVER_VEHICLE",
  JOURNEY_SCHEDULED = "SCHEDULED_JOURNEY",
  JOURNEY_COMPLETED = "COMPLETED_JOURNEY",
  JOURNEY_INPROGRESS = "INPROGRESS_JOURNEY",
  JOURNEY_FAILED = "FAILED_JOURNEY",
  LEZ = "LEZ"
}
