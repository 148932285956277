import {StyledIconButton, StyledIconButtonProps } from "./styles";


interface PropsType extends StyledIconButtonProps, Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "color"> {
  icon?: string;
  iconClass?: string;
  customIcon?: React.ReactNode;
}

export const IconButton = ({ icon, iconClass, customIcon, ...rest }: PropsType) => {
  return (
    <StyledIconButton {...rest}>
      {customIcon ? (
        customIcon
      ) : (
        <i className={`${iconClass ? iconClass : "fas"} fa-${icon}`}></i>
      )}
    </StyledIconButton>
  );
};
