    import {  useState } from 'react';

    import { Formik, Field, FieldProps, Form, FormikHelpers } from 'formik';
    import {  Geometry } from "geojson";
    import {  LatLngBoundsExpression } from 'leaflet';
    import { Spinner } from 'react-bootstrap';
    import { MapContainer, TileLayer, Polygon as LeafletPolygon } from 'react-leaflet';
    import shp, { FeatureCollectionWithFilename } from "shpjs";

    import { useAddLezMutation, useEditLezMutation } from "@evr/apis/lez";
    import { useGetLezById } from "@evr/apis/lez/hooks/useGetLezById";
    import CountryTypeahead from '@evr/components/CountryTypeahead/CountryTypeahead';
    import { 
        FormikSelect, 
        FormikTextInput, 
        FormikTimeRange
    } from "@evr/components/Form";
    import { 
        Dialogs, 
        DialogTitle, 
        lezInitialValues, 
        OperationDaysOptions ,
        LezKeys
    } from "@evr/constant";
    import { useAppSelector } from "@evr/hooks/reduxHooks";
    import { selectDialog } from "@evr/store/slices/dialog";
    import { apiErrors, Country, Lez } from "@evr/types";
    import { Button } from '@evr/ui/Button';
    import { 
        DialogActionButtons, 
        DialogBody, 
        DialogHeader 
    } from "@evr/ui/Dialog";
    import { Flex, GridItem } from "@evr/ui/FlexBox";
    import { Label } from '@evr/ui/TextInput/styles';
    import { Typography } from "@evr/ui/Typography";
    import { transformErrorFromApi } from "@evr/utils";

    import { step1ValidationSchema, step2ValidationSchema } from "./schema"; 

    export const LezDialog = () => {
        const [currentStep, setCurrentStep] = useState(1);
        const [isProcessingShapefile, setIsProcessingShapefile] = useState(false);

        const { id, type } = useAppSelector(selectDialog); 
        const lez = useGetLezById(id || -1);

        const [addLez, { isLoading: addLoading }] = useAddLezMutation();
        const [editLez, { isLoading: editLoading }] = useEditLezMutation();
        const isAdding = type === Dialogs.ADD_LEZ;

        const getButtonTitle = (): string => (isAdding ? "Add" : "Edit");

        type GeoJSONResult = FeatureCollectionWithFilename | FeatureCollectionWithFilename[];

        const handleShapefileUpload = async (
            event: React.ChangeEvent<HTMLInputElement>,
            form: any
        ) => {
            const file = event.currentTarget.files ? event.currentTarget.files[0] : null;
            form.setFieldValue("shapefile", file);

            if (file) {
                setIsProcessingShapefile(true); 
                const reader = new FileReader();
                reader.onload = async (e) => {
                    if (e.target?.result) {
                        const arrayBuffer = e.target.result as ArrayBuffer;
                        try {
                            const geojson: GeoJSONResult = await shp(arrayBuffer);

                            let geometry: Geometry | null = null;

                            if (Array.isArray(geojson)) {
                                if (geojson.length > 0 && isFeatureCollectionWithFilename(geojson[0])) {
                                    geometry = geojson[0].features[0]?.geometry || null;
                                }
                            } else if (isFeatureCollectionWithFilename(geojson)) {
                                geometry = geojson.features[0]?.geometry || null;
                            }

                            if (geometry && geometry.type === "Polygon") {
                                form.setFieldValue(LezKeys.ZONE, geometry);
                            } else {
                                form.setFieldValue(LezKeys.ZONE, null);
                                form.setFieldError("shapefile", "Shapefile does not contain a valid Polygon geometry.");
                            }
                        } catch (conversionError) {
                            console.error("Shapefile conversion error:", conversionError);
                            form.setFieldValue(LezKeys.ZONE, null);
                            form.setFieldError("shapefile", "Invalid shapefile or unable to convert.");
                        } finally {
                            setIsProcessingShapefile(false);
                        }
                    }
                };
                reader.readAsArrayBuffer(file);
            } else {
                form.setFieldValue(LezKeys.ZONE, null);
            }
        };

        function isFeatureCollectionWithFilename(
            geojson: GeoJSONResult
        ): geojson is FeatureCollectionWithFilename {
            return (geojson as FeatureCollectionWithFilename).type !== undefined;
        }

        const handleSubmit = async (values: Lez, helpers: FormikHelpers<Lez>) => {
            const submittedLez: Lez = { ...values };
            try {
                if (isAdding) {
                    await addLez(submittedLez).unwrap();
                } else {
                    await editLez(submittedLez).unwrap();
                }
            } catch (error) {
                const apiErr = error as { data: apiErrors };
                if (apiErr && apiErr.data.errors) {
                    helpers.setErrors(transformErrorFromApi(apiErr.data.errors));
                }
            }
        };
        
        const calculateBounds = (coordinates: number[][][]): LatLngBoundsExpression => {
            const allCoords = coordinates.flat();

            const latitudes = allCoords.map(coord => coord[1]);
            const longitudes = allCoords.map(coord => coord[0]);

            const minLat = Math.min(...latitudes);
            const maxLat = Math.max(...latitudes);
            const minLng = Math.min(...longitudes);
            const maxLng = Math.max(...longitudes);

            return [
                [minLat, minLng],
                [maxLat, maxLng],
            ];
        };
        return (
            <>
                <DialogHeader title={isAdding ? DialogTitle.ADD_LEZ : DialogTitle.EDIT_LEZ} />
                <DialogBody>
                    <Formik<Lez>
                        enableReinitialize 
                        initialValues={lez ?? lezInitialValues}
                        
                        validationSchema={currentStep === 1 ? step1ValidationSchema : step2ValidationSchema(isAdding)}
                        onSubmit={handleSubmit}
                    >
                        {({ values, setFieldValue, errors, touched, validateForm, setTouched }) => {
                            const bounds: LatLngBoundsExpression | null = values.zone && values.zone.type === "Polygon"

                                ? calculateBounds(values.zone.coordinates)
                                : null;

                            const handleNext = async () => {
                                const formErrors = await validateForm();
                                setTouched({
                                    ...touched,
                                    ...Object.keys(step1ValidationSchema.fields).reduce((acc, key) => {
                                        acc[key] = true;
                                        return acc;
                                    }, {} as Record<string, boolean>)
                                });
                                const step1Fields = Object.keys(step1ValidationSchema.fields);
                                const hasErrors = step1Fields.some(field => formErrors[field as keyof Lez]);
                                if (!hasErrors) {
                                    setCurrentStep(2);
                                }
                            };
                            const handleBack = () => {
                                setCurrentStep(1);
                            };

                            return (
                                <Form>
                                    {currentStep === 1 && (
                                        <Flex wrap="wrap" align="baseline" justify="flex-start">
                                            <GridItem xs={12} sm={6} >
                                                <FormikTextInput
                                                    label="Name"
                                                    name={LezKeys.NAME}
                                                    type="text"
                                                    required={isAdding}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={6}>
                                                <FormikTimeRange
                                                    label="Operation Hours"
                                                    name={LezKeys.OPERATION_HOURS}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={6}>
                                                <FormikTextInput
                                                    label="Effective Start Date"
                                                    name={LezKeys.EFFECTIVE_START}
                                                    type="date"
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={6}>
                                                <FormikTextInput
                                                    label="Effective End Date"
                                                    name={LezKeys.EFFECTIVE_END}
                                                    type="date"
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={6}>
                                                <FormikTextInput
                                                    label="City"
                                                    name={LezKeys.CITY}
                                                    type="text"
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={6} >
                                            <Label style={{ marginLeft: '10px' }}>
                                            Country
                                            </Label>
                                            <CountryTypeahead
                                            handleChange={(country: Country) => {
                                                setFieldValue(`${LezKeys.CURRENCY}`, country.currencySymbol);
                                                setFieldValue(LezKeys.COUNTRY, country.name);
                                              }}
                                              clear={() => {
                                                setFieldValue(`${LezKeys.CURRENCY}`, "$");
                                                setFieldValue(LezKeys.COUNTRY, "");
                                              }}
                                              name={`${LezKeys.COUNTRY}`}
                                            />
                                            </GridItem>
                                            <GridItem xs={12} sm={6}>
                                                <label htmlFor={LezKeys.COST} style={{ marginBottom: "0.5rem", marginLeft: "0.5rem", display: "block" }}> 
                                                    Cost
                                                </label>
                                                <div style={{ display: "flex", alignItems: "center", marginTop: "0.5rem" }}> 
                                                    <span
                                                    style={{
                                                        marginRight: "5 px",
                                                        fontWeight: "bold",
                                                    }}
                                                    >
                                                    {values.currency || "$"}
                                                    </span>
                                                    <FormikTextInput
                                                    name={LezKeys.COST}
                                                    type="number"
                                                    placeholder="Enter cost"
                                                    style={{ flex: 1 }}
                                                    />
                                                </div>
                                                {errors[LezKeys.COST] && touched[LezKeys.COST] && (
                                                    <div className="error" style={{ color: "red", marginTop: "5px" }}>
                                                    {errors[LezKeys.COST]}
                                                    </div>
                                                )}
                                                </GridItem>


                                            <GridItem xs={12} sm={6}>
                                                <FormikSelect 
                                                    label="Operation Period" 
                                                    name={LezKeys.OPERATION_DAYS} 
                                                    items={OperationDaysOptions} 
                                                />
                                                {errors[LezKeys.OPERATION_DAYS] && touched[LezKeys.OPERATION_DAYS] && (
                                                    <div className="error" style={{ color: 'red', marginTop: '5px' }}>
                                                        {errors[LezKeys.OPERATION_DAYS]}
                                                    </div>
                                                )}
                                            </GridItem>   
                                            <GridItem
                                        xs={16}
                                        sm={10}
                                        style={{
                                            display: "flex",
                                            marginLeft: "15px", 
                                            justifyContent: "flex-start", 
                                        }}
                                        >
                                            <label>
                                                    <Field type="checkbox" name={LezKeys.IS_ACTIVE} 
                                                            style={{
                                                            transform: "scale(1.5)", 
                                                            cursor: "pointer", 
                                                            }} 
                                                    />
                                                    <span style={{ marginLeft: '10px' }}>Active</span>
                                                </label>
                                                {errors[LezKeys.IS_ACTIVE] && touched[LezKeys.IS_ACTIVE] && (
                                                    <div className="error" style={{ color: 'red', marginTop: '5px' }}>
                                                        {errors[LezKeys.IS_ACTIVE]}
                                                    </div>
                                                )}
                                            </GridItem>                             
                                        </Flex>
                                    )}

                                    {currentStep === 2 && (
                                        <Flex wrap="wrap" align="baseline" justify="flex-start">
                                            {/* Shapefile Upload Field */}
                                            <GridItem key="shapefile-upload" xs={12} sm={6}>
                                                <Typography>
                                                    <strong>Upload Shapefile:</strong>
                                                </Typography>
                                                <Field name="shapefile">
                                                    {({ form }: FieldProps<Lez>) => (
                                                        <>
                                                            <Field name="shapefile">
                                                            {({ form }: FieldProps<Lez>) => (
                                                                <>
                                                                <input
                                                                    type="file"
                                                                    accept=".zip"
                                                                    id="shapefile-upload"
                                                                    style={{ display: 'none' }}
                                                                    onChange={(e) => handleShapefileUpload(e, form)}
                                                                />
                                                                <Button
                                                                    margin="0"
                                                                    fullHeight
                                                                    borderRadius="4px"
                                                                    onClick={() => document.getElementById("shapefile-upload")?.click()}
                                                                    style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    width: '30px',
                                                                    height: '30px',
                                                                    }}
                                                                >
                                                                    <i className="fas fa-plus" />
                                                                </Button>
                                                                </>
                                                            )}
                                                            </Field>



                                                            {errors.shapefile && touched.shapefile && (
                                                                <div className="error" style={{ color: 'red', marginTop: '5px' }}>
                                                                    {errors.shapefile}
                                                                </div>
                                                            )}
                                                            {isProcessingShapefile && (
                                                                <Spinner 
                                                                    animation="border" 
                                                                    role="status" 
                                                                    size="sm" 
                                                                    style={{ marginLeft: '10px' }}
                                                                >
                                                                    <span className="visually-hidden">Processing...</span>
                                                                </Spinner>
                                                            )}
                                                            {values.zone && values.zone.type === "Polygon" && (
                                                                <MapContainer
                                                                    bounds={bounds || [[0, 0], [0, 0]]} 
                                                                    style={{ height: '300px', width: '100%', marginTop: '10px' }}
                                                                    scrollWheelZoom={false} 
                                                                >
                                                                    <TileLayer
                                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                                        attribution="&copy; OpenStreetMap contributors"
                                                                    />
                                                                    <LeafletPolygon
                                                                        positions={values.zone.coordinates[0].map((coord: number[]) => [coord[1], coord[0]])} 
                                                                        pathOptions={{ color: 'blue' }}
                                                                    />
                                                                </MapContainer>
                                                            )}
                                                        </>
                                                    )}
                                                </Field>
                                            </GridItem>
                                        </Flex>
                                    )}
                                    <Flex 
                                        justify={currentStep === 1 ? "flex-end" : "space-between"} 
                                        style={{ marginTop: '20px' }}
                                    >
                                        {currentStep === 2 && (
                                            <Button variant="text" onClick={handleBack}>
                                            Back
                                            </Button>      
                                        )}

                                        {currentStep === 1 ? (
                                            <Button fontSize="1.3rem" type="submit" padding="0.5rem" fullWidth
                                                onClick={handleNext}>
                                                Next
                                            </Button>
                                        ) : (
                                            <DialogActionButtons  
                                                loading={addLoading || editLoading}
                                                buttonTitle={getButtonTitle()}       
                                            />
                                        )}
                                    </Flex>
                                </Form>
                            );
                        }}
                    </Formik>
                </DialogBody>
            </>
        );
    };
