import * as Yup from "yup";

import { OperationDaysOptions } from "@evr/constant";
import { validationMsg } from "@evr/constant";

export const step1ValidationSchema = Yup.object().shape({
    name: Yup.string()
        .required("Name is required")
        .required(validationMsg.required)
        .max(100, validationMsg.maxlength(100)),

        operationHours: Yup.object()
        .nullable()
        .shape({
            start: Yup.string(),
            end: Yup.string(),
        })
        .test(
            "start-before-end",
            "Start time must be before or the same as end time",
            (value) => {
                if (!value || !value.start || !value.end) {
                    return true; 
                }
                return value.start <= value.end; 
            }
        ),
        effectiveStart: Yup.date()
        .nullable()
        .typeError("Effective start must be a valid date"),
    
    effectiveEnd: Yup.date()
        .nullable()
        .typeError("Effective end must be a valid date")
        .test(
            "start-before-end",
            "Effective start date must be before or the same as effective end date",
            function (value) {
                const { effectiveStart } = this.parent; 
                if (!effectiveStart || !value) {
                    return true; 
                }
                return effectiveStart <= value; 
            }
        ),
    
    city: Yup.string()
        .required("City is required"),
    
    country: Yup.string()
        .required("Country is required"),
    
    isActive: Yup.boolean()
        .required("Active status is required")
        .typeError("Active status must be a boolean"),
    
    cost: Yup.number()
        .typeError("Cost must be a number")
        .required("Cost is required")
        .min(0, "Cost must be a non-negative number"),

    operationDays: Yup.string()
        .oneOf(OperationDaysOptions.map(option => option.value), "Invalid Operation Period")
        .required("Operation days are required"),
});

export const step2ValidationSchema = (isAdding: boolean) => {
    return Yup.object({
        shapefile: isAdding
            ? Yup.mixed()
                  .required("A shp.zip file is required")
                  .test(
                      "fileType",
                      "Only .zip files are allowed",
                      (value) => {
                          return value && value.name.endsWith(".zip");
                      }
                  )
            : Yup.mixed()
                  .notRequired()
                  .test(
                      "fileType",
                      "Only shp.zip files are allowed",
                      (value) => {
                          return !value || (value && value.name.endsWith(".zip"));
                      }
                  ),
    });
};