export enum CountryKeys {
  ID = "id",
  NAME = "name",
  IS_EU = "isEu",
  DEFAULT_CURRENCY_CODE = "defaultCurrencyCode",
  COUNTRY_CODE = "countryCode",
  CALLING_CODE = "callingCode",
  CURRENCY_SYMBOL = "currencySymbol",
}
export enum CountryCurrencyKeys {
  ID = "id",
  COUNTRYNAME = "countryName",
  CURRENCY = "currency",
  COUNTRYCODE = "countryCode",
  CURRENCYSYMBOL = "currencySymbol"
}
