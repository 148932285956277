import { useDeleteLezMutation } from "@evr/apis/lez";
import { useGetLezById } from "@evr/apis/lez/hooks/useGetLezById";
import { DialogTitle } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectDialog } from "@evr/store/slices/dialog";
import { DialogActionButtons, DialogBody, DialogHeader } from "@evr/ui/Dialog";
import { Typography } from "@evr/ui/Typography";

export const DeleteLezDialog = () => {
  const { id } = useAppSelector(selectDialog);
  const lez = useGetLezById(id || -1);

  const [deleteLez, { isLoading }] = useDeleteLezMutation();

  const handleAction = () => {

    if (!id || !lez) {
      return;
    }
    deleteLez(id);
  };

  return (
    <>
      <DialogHeader title={DialogTitle.DELETE_LEZ} />
      <DialogBody>
        <Typography>
          Are you sure to delete this LEZ?
          <br />
          <small>{lez ? `Name: ${lez?.name}` : "Error finding the LEZ."}</small>
        </Typography>
        <DialogActionButtons loading={isLoading} buttonTitle={"Delete"} onClickAction={handleAction} />
      </DialogBody>
    </>
  );
};
