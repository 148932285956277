  import { Lez } from "@evr/types";

  export enum LezKeys {
      ID = "id",
      NAME = "name",
      COST = "cost",
      OPERATION_DAYS = "operationDays",
      OPERATION_HOURS = "operationHours",
      EFFECTIVE_START = "effectiveStart",
      EFFECTIVE_END = "effectiveEnd",
      IS_ACTIVE = "isActive",
      UPDATED_DATE = "updatedDate",
      CREATED_DATE = "createdDate",
      START_HOUR = "startHour",
      END_HOUR = "endHour",
      ZONE = "zone",
      CITY = "city",
      COUNTRY = "country",
      SHAPEFILE = "shapefile",
      CURRENCY = "currency",
    }

  export enum OperationDays {
      WEEKEND = "Weekends",
      WEEKDAY = "Weekdays",
      EVERYDAY = "EveryDay",

    }

  export const OperationDaysOptions = Object.entries(OperationDays).map(([key, value]) => ({ key, value }));

  export const lezInitialValues: Lez = {
    [LezKeys.ID]: -1,
    [LezKeys.NAME]: "",
    [LezKeys.COST]: 0,
    [LezKeys.OPERATION_DAYS]: OperationDays.EVERYDAY,
    [LezKeys.EFFECTIVE_START]: null,
    [LezKeys.IS_ACTIVE]: true,
    [LezKeys.EFFECTIVE_END]: null,
    [LezKeys.UPDATED_DATE]: "",
    [LezKeys.OPERATION_HOURS]: null,
    [LezKeys.ZONE]: null,
    [LezKeys.CITY]: "",
    [LezKeys.COUNTRY]: "",
    [LezKeys.SHAPEFILE]: null,
    [LezKeys.CURRENCY]: "$",
    [LezKeys.START_HOUR]: "",
    [LezKeys.END_HOUR]: "",
  };