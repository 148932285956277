import { useEditLezMutation } from "@evr/apis/lez";
import { TableSortableHeader } from "@evr/components/Table";
import { TableNameKeys, LezKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { selectLezsPagination, setLezSortColumn } from "@evr/store/slices/lez";
import { Lez } from "@evr/types";
import { TR, StyledTable, TH, TD } from "@evr/ui/Table";

import { LezTableActions } from "./LezTableActions";
import { lezTableColumns } from "./lezTableColumns";

interface LezTableProps {
  lezs: Lez[];
}

export const LezTable = ({ lezs }: LezTableProps) => {
  const [editLez] = useEditLezMutation();
  const dispatch = useAppDispatch();
  const { sort } = useAppSelector(selectLezsPagination);

  const handleChangeSortColumn = (column: string) => {
    dispatch(setLezSortColumn(column));
  };

  const formatDate = (dateString: string | null | undefined): string => {
    if (!dateString) {
      return "_";
    }
    const date = new Date(dateString);
    if (isNaN(date.getTime()))
      { 
        return "_";
      }
    return date.toLocaleDateString("en-GB");
  };

  const parseDateString = (dateStr?: string | null): string | null => {
    if (!dateStr)
      { return null;
      }
    const [day, month, year] = dateStr.split("/");
    const isoString = new Date(`${year}-${month}-${day}T00:00:00Z`);
  
    return isNaN(isoString.getTime()) ? null : isoString.toISOString();
  };
  
  const handleToggleIsActive = async (lez: Lez) => {
    try {
      await editLez({
        ...lez,
        isActive: !lez.isActive,
        effectiveStart: parseDateString(lez.effectiveStart),
        effectiveEnd: parseDateString(lez.effectiveEnd),
      }).unwrap();
    } catch (error) {
      console.error("Failed to toggle isActive", error);
    }
  };
  
  
  const lezList: Lez[] = lezs.map(lez => ({
    ...lez,
    updatedDate: formatDate(lez.updatedDate),
    effectiveStart: formatDate(lez.effectiveStart),
    effectiveEnd: formatDate(lez.effectiveEnd),
    }));

  return (
    <StyledTable tableType={TableNameKeys.LEZ}>
      <thead>
        <tr>
          {lezTableColumns.map(col => (
            <TH key={col.field} padding="0" tableType={TableNameKeys.LEZ}>
              <TableSortableHeader sort={sort} column={col} onChange={handleChangeSortColumn} />
            </TH>
          ))}
          <TH tableType={TableNameKeys.LEZ} textAlign="center">
            Actions
          </TH>
        </tr>
      </thead>
      <tbody>
        
        {lezList.map(lez => (
          <TR key={lez.id}>
            {lezTableColumns.map(col => (
                  <TD key={col.field}>
                  {col.field === LezKeys.COST
                    ? `${lez.currency ?? "$"} ${Number(lez.cost).toFixed(2)}`
                    : col.field === LezKeys.START_HOUR
                    ? `${lez.operationHours ? lez.operationHours.start : "_"}`
                    : col.field === LezKeys.END_HOUR
                    ? `${lez.operationHours ? lez.operationHours.end : "_"}`
                    : col.field === LezKeys.IS_ACTIVE
                    ? (
                      <input
                        type="checkbox"
                        checked={lez.isActive}
                        onChange={() => handleToggleIsActive(lez)}
                        style={{ transform: "scale(1.2)", cursor: "pointer" }}
                      />
                    ): 
                    lez[col.field] || "_"}
                </TD>
            ))}
            <LezTableActions lez={lez} />
          </TR>
          
        ))}
      </tbody>
    </StyledTable>
  );
};
