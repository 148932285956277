import { Lez } from "src/types/lez";

import {  Dialogs } from "@evr/constant";
import { useAppDispatch } from "@evr/hooks/reduxHooks";
import { openDialog } from "@evr/store/slices/dialog";
import { TableAction } from "@evr/ui/Table";

interface LezTableActionProps {
  lez: Lez;
}

export const LezTableActions = ({ lez }: LezTableActionProps) => {
  const dispatch = useAppDispatch();
  const { id } = lez;

  const handleDeleteLez = () => dispatch(openDialog({ type: Dialogs.DELETE_LEZ, id }));

  const handleEditLez = () => dispatch(openDialog({ type: Dialogs.EDIT_LEZ, id }));

  return <TableAction id={id} onRemoveClick={handleDeleteLez} onEditClick={handleEditLez} />;
};
