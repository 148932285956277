import { DialogTitle } from "@evr/constant";
import { useAppSelector } from "@evr/hooks/reduxHooks";
import { selectUserOrgSettings } from "@evr/store/slices/auth";
import { BatteryBufferType } from "@evr/types";
import { DialogBody, DialogHeader } from "@evr/ui/Dialog";
import { Divider } from "@evr/ui/Divider";

import { SettingBatteryBuffer } from "./SettingBatteryBuffer";
import { SettingBatteryBufferDistance } from "./SettingBatteryBufferDistance";
import { SettingBatteryBufferType } from "./SettingBatteryBufferType";
import { SettingFuelPrices } from "./SettingFuelPrices";
import { SettingInformationCard } from "./SettingInformationCard";
import { SettingJobDuration } from "./SettingJobDuration";
import { SettingJourneyColors } from "./SettingJourneyColors";
import { SettingMapColor } from "./SettingMapColor";
import { SettingMaxWaitingTime } from "./SettingMaxWaitingTime";
import { SettingProofOfDeliverySelect } from "./SettingProofOfDeliverySelect";
import { SettingResetPassword } from "./SettingResetPassword";
import { SettingUnitOfMeasurement } from "./SettingUnitOfMeasurement";
import { SettingsVerizonImport } from "./SettingVerizonImport";
import { ToggleAvoidLezs } from "./ToggleAvoidLezs";
import { ToggleEnableRecharge } from "./ToggleEnableRecharge";
import { ToggleEvPrioritisation } from "./ToggleEvPrioritisation";
import { ToggleMonitorDrivers } from "./ToggleMonitorDrivers";
import { ToggleSpreadWork } from "./ToggleSpreadWork";
import { ToggleViewLezs } from "./ToggleViewLezs";


export const SettingsDialog = () => {
  const orgSettings = useAppSelector(selectUserOrgSettings);
  return (
    <>
      <DialogHeader title={DialogTitle.SETTING} />
      <DialogBody position="relative">
        <SettingInformationCard />
        <Divider direction="h" />
        <SettingBatteryBufferType />
        {orgSettings.batteryBufferType == BatteryBufferType.PERCENTAGE ? (
          <SettingBatteryBuffer />
        ) : (
          <SettingBatteryBufferDistance />
        )}
        <SettingJobDuration />
        <SettingMaxWaitingTime />
        <ToggleSpreadWork />
        <ToggleEvPrioritisation />
        <ToggleEnableRecharge />
        <ToggleMonitorDrivers /> 
        <ToggleViewLezs />
        <ToggleAvoidLezs />
        <SettingProofOfDeliverySelect />
        <Divider direction="h" />
        <SettingMapColor />
        <SettingJourneyColors />
        <Divider direction="h" />
        <SettingUnitOfMeasurement />
        <Divider direction="h" />
        <SettingFuelPrices />
        <Divider direction="h" />
        <SettingResetPassword />
        <Divider direction="h" />
        <SettingsVerizonImport />
      </DialogBody>
    </>
  );
};
