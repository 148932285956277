import { useEffect, useMemo, useState } from "react";

import { inAndOut } from "ol/easing";
import * as olProj from "ol/proj";
import olOSMSource from "ol/source/OSM";

import Interactions from "@evr/components/ReactOL/Interactions/Interactions";
import { Overlays } from "@evr/components/ReactOL/overlays";
import { ActiveLayerTypes, MapColorKeys } from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { useApi } from "@evr/hooks/useApi";
import { getAllLiveJourneyAsync } from "@evr/store/slices/all-live-journeys-result/asyncActions";
import { selectUserOrgSettings } from "@evr/store/slices/auth";
import { selectActiveLayer, setActiveLayer } from "@evr/store/slices/map";
import { selectSettingState } from "@evr/store/slices/setting";
import { selectSidebarOpenStatus } from "@evr/store/slices/sidebar";

import { Layers, Tile } from "../../ReactOL/Layers";
import { Map } from "../../ReactOL/Map";
import { OSMGreyscale } from "../../ReactOL/Source";
import ConstrainedView from "../../ReactOL/Views/ConstrainedView";
import ActionButtons from "../ActionButtons";
import ParcelActionButtons from "../ActionButtons/Parcel";
import ParcelInteractions from "../Interractions/Parcel";
import AllJourneysLayer from "../Layers/AllJourneysLayer";
import DepotLayer from "../Layers/DepotLayer";
import LezLayer from "../Layers/LezLayer";
import ParcelLayer from "../Layers/ParcelLayer";
import ResultLayer from "../Layers/ResultsLayer";
import ScheduledJourneyLayer from "../Layers/ScheduledJourneyLayer";
import DepotOverlays from "../Overlays/Depot";
import ParcelOverlays from "../Overlays/Parcel";
import ResultOverlays from "../Overlays/Result";
import ScheduledJourneyDepotInfo from "../Overlays/ScheduledJourney/DepotInfo";

const OlMap = () => {
  const { mapColor } = useAppSelector(selectSettingState);

  const dispatch = useAppDispatch();
  const api = useApi(getAllLiveJourneyAsync);
  const open = useAppSelector(selectSidebarOpenStatus);

  useEffect(() => {
    const handleInitiateLiveMode = async () => {
      dispatch(setActiveLayer(ActiveLayerTypes.ALL_JOURNEYS));
      await api.sendRequest();
    };
    if (window.location.href.toLowerCase().includes("livemode")) {
      handleInitiateLiveMode();
    }
  }, [window.location.href]);

  const [osm, setOsm] = useState(mapColor === MapColorKeys.GREYSCALE ? OSMGreyscale() : new olOSMSource());
  useEffect(() => {
    setOsm(mapColor === MapColorKeys.GREYSCALE ? OSMGreyscale() : new olOSMSource());
  }, [mapColor]);

  const projection = useMemo(() => olProj.get("EPSG:3857"), []);
  const view = useMemo(() => {
    if (!projection) {
      return;
    }
    return new ConstrainedView({
      center: olProj.fromLonLat([-0.1281, 51.5081]),
      zoom: 13,
      projection: projection,
      minZoom: 1,
      maxZoom: 20,
    });
  }, [projection]);

  // Debounce function
  const debounce = (func: any, delay: number) => {
    let timeoutId: any;
    return function (...args: any) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const fitViewToExtent = (extent: number[]) => {
    if (!view || !extent.every(num => isFinite(num))) {
      return;
    }

    view.fit(extent, {
      duration: 1000,
      easing: inAndOut,
      padding: [150, open ? 510 : 150, 150, 150],
    });
  };

  const debouncedFit = useMemo(() => debounce(fitViewToExtent, 300), [open]);

  const activeLayer = useAppSelector(selectActiveLayer);

const orgSettings = useAppSelector(selectUserOrgSettings);

  return (
    <>
      {view && (
        <Map view={view}>
          <Overlays>
            <ScheduledJourneyDepotInfo />
            <ResultOverlays />
            <DepotOverlays />
            <ParcelOverlays />
          </Overlays>
          <ActionButtons>
            <ParcelActionButtons />
          </ActionButtons>
          <Interactions>
            <ParcelInteractions />
          </Interactions>
          <Layers>
            <Tile zIndex={1} source={osm}></Tile>
            {orgSettings.viewLezs === true && <LezLayer />}
            {activeLayer === ActiveLayerTypes.DEPOTS && <DepotLayer />}
            {activeLayer === ActiveLayerTypes.RESULT && <ResultLayer fitViewToExtent={debouncedFit} />}
            {activeLayer === ActiveLayerTypes.SCHEDULED_JOURNEY && (
              <ScheduledJourneyLayer fitViewToExtent={debouncedFit} />
            )}
            {activeLayer === ActiveLayerTypes.ALL_JOURNEYS && <AllJourneysLayer fitViewToExtent={debouncedFit} />}
            {activeLayer === ActiveLayerTypes.PARCEL && <ParcelLayer fitViewToExtent={debouncedFit} />}
          </Layers>
        </Map>
      )}
    </>
  );
};

export default OlMap;
