import { config } from "@evr/config";
import { BatteryBufferType, OrgSettings } from "@evr/types";

export enum MapColorKeys {
  GREYSCALE = "greyscale",
  COLOURED = "coloured",
}
export enum CurrencySettingsKeys {
  POUND = "pound",
  DOLLAR = "dollar",
  EURO = "euro",
}
export enum DistanceUnitKeys {
  MILES = "Miles",
  KM = "Km",
}
export enum ProofOfDeliveryRequirement {
  NONE = 0,
  SIGNATURE_REQUIRED = 1,
  PHOTO_REQUIRED = 2,
  SIGNATURE_AND_PHOTO = 3,
}

export const OrgSettingsInitialValues: OrgSettings = {
  batteryBuffer: config.batteryBuffer.decimal.default,
  defaultJobDuration: 2,
  defaultInitialStateOfCharge: 100,
  driverMaxWaitingTime: 15,
  prioritiseEVs: true,
  spreadWork: false,
  enableRecharge: false,
  monitorDriverInactivity: false,
  batteryBufferDistance: 0,
  batteryBufferType: BatteryBufferType.PERCENTAGE,
  electricityCostOverride: undefined,
  dieselCostOverride: undefined,
  petrolCostOverride: undefined,
  proofOfDeliveryRequirement: 0,
  activityTimeout: 30,
  verizonLastImportDate: undefined,
  viewLezs: true,
  avoidLezs: false,
};
