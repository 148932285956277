import { Dialogs } from "@evr/constant";

import { DepotDialog } from "./DepotDialog";
import { DeleteDepotDialog } from "./DepotDialog/Delete";
import { DriverDialog } from "./DriverDialog";
import { DeleteDriverDialog } from "./DriverDialog/Delete";
import { JobLocationDialog } from "./JobLocationDialog";
import { DeleteJobLocationDialog } from "./JobLocationDialog/Delete";
import { LezDialog} from "./LezDialog";
import { DeleteLezDialog } from "./LezDialog/Delete";
import { NoSubscriptionDialog } from "./NoSubscriptionDialog";
import { NotificationsDialog } from "./NotificationsDialog";
import { PlanDialog } from "./PlanDialog";
import { SavingsEnergyDialog } from "./SavingsEnergyDialog";
import { SettingsDialog } from "./SettingsDialog";
import { TermsConditionsDialog } from "./TermsConditionsDialog";
import { VehicleDialog } from "./VehicleDialog";
import { DeleteVehicleDialog } from "./VehicleDialog/Delete";
import { VerizonDriverSelectDialog } from "./Verizon/DriverSelectDialog";
import { VerizonImportDialog } from "./Verizon/ImportDialog";
import { VerizonImportInterruptedDialog } from "./Verizon/ImportInterruptedDialog";
import { VerizonNewUserDialog } from "./Verizon/NewUserDialog";
import { VerizonVehicleSelectDialog } from "./Verizon/VehicleSelectDialog";
import { VerizonLoginDialog } from "./Verizon/VerizonLoginDialog";

interface DialogsContent {
  component: () => JSX.Element | null;
  maxWidth?: string;
}

export const dialogs: Record<Dialogs, DialogsContent> = {
  [Dialogs.ADD_DRIVER]: { component: DriverDialog },
  [Dialogs.EDIT_DRIVER]: { component: DriverDialog },
  [Dialogs.DELETE_DRIVER]: { component: DeleteDriverDialog },

  [Dialogs.ADD_VEHICLE]: { component: VehicleDialog },
  [Dialogs.EDIT_VEHICLE]: { component: VehicleDialog },
  [Dialogs.DELETE_VEHICLE]: { component: DeleteVehicleDialog },

  [Dialogs.ADD_DEPOT]: { component: DepotDialog },
  [Dialogs.EDIT_DEPOT]: { component: DepotDialog },
  [Dialogs.DELETE_DEPOT]: { component: DeleteDepotDialog },

  [Dialogs.PLAN]: { component: PlanDialog },
  [Dialogs.SETTING]: { component: SettingsDialog },
  [Dialogs.SAVING_ENERGY]: { component: SavingsEnergyDialog },
  [Dialogs.NOTIFICATIONS]: { component: NotificationsDialog },
  [Dialogs.TERMS_CONDITIONS]: { component: TermsConditionsDialog },
  [Dialogs.NO_SUBSCRIPTION]: { component: NoSubscriptionDialog },

  [Dialogs.DELETE_JOB_LOCATION]: { component: DeleteJobLocationDialog },
  [Dialogs.ADD_JOB_LOCATION]: { component: JobLocationDialog },
  [Dialogs.EDIT_JOB_LOCATION]: { component: JobLocationDialog },

  [Dialogs.VERIZON_LOGIN]: { component: VerizonLoginDialog },
  [Dialogs.VERIZON_DRIVER_SELECT]: { component: VerizonDriverSelectDialog, maxWidth: "1000" },
  [Dialogs.VERIZON_DRIVER_CREATE]: { component: DriverDialog },
  [Dialogs.VERIZON_VEHICLE_SELECT]: { component: VerizonVehicleSelectDialog },
  [Dialogs.VERIZON_IMPORT]: { component: VerizonImportDialog, maxWidth: "1000" },
  [Dialogs.VERIZON_INTERRUPTED]: { component: VerizonImportInterruptedDialog },
  [Dialogs.VERIZON_NEW_USER]: { component: VerizonNewUserDialog },

  [Dialogs.CLOSE]: { component: () => null },
  [Dialogs.ADD_LEZ]: { component: LezDialog },
  [Dialogs.EDIT_LEZ]: { component: LezDialog },
  [Dialogs.DELETE_LEZ]: { component: DeleteLezDialog }
};
