import { Field, Form, Formik, FormikHelpers } from "formik";

import { useAddVehicleMutation, useEditVehicleMutation } from "@evr/apis/vehicle";
import { useGetVehicleById } from "@evr/apis/vehicle/hooks";
import { FormikSelect, FormikTextInput } from "@evr/components/Form";
import {
  Dialogs,
  DialogTitle,
  EngineType,
  engineTypeOptions,
  plugSetOptions,
  vehicleInitialValues,
  VehicleKeys,
  vehicleLowerKwBounds,
  VehicleSize,
  vehicleSizeOptions,
  vehicleUpperKwBounds,
} from "@evr/constant";
import { useAppDispatch, useAppSelector } from "@evr/hooks/reduxHooks";
import { closeDialog, openDialog, selectDialog } from "@evr/store/slices/dialog";
import {
  getCurrentVehicle,
  getCurrentVehicleIndex,
  getTotalVehicles,
  isVerizonImporting,
  setEvrVehicle,
  setImportPosition,
  setVehicleIndex,
  VerizonImportPosition,
} from "@evr/store/slices/verizon";
import { apiErrors, Vehicle } from "@evr/types";
import { DialogActionButtons, DialogBody, DialogHeader } from "@evr/ui/Dialog";
import { Flex, GridItem } from "@evr/ui/FlexBox";
import { transformErrorFromApi } from "@evr/utils";

import { RangeRatioWarning } from "./rangeRatioWarning";
import { VehicleValidationSchema } from "./schema";

export const VehicleDialog = () => {
  const dispatch = useAppDispatch();
  const { id } = useAppSelector(selectDialog);
  const vehicle = useGetVehicleById(id || -1);

  const [addVehicle, { isLoading: addLoading }] = useAddVehicleMutation();
  const [editVehicle, { isLoading: editLoading }] = useEditVehicleMutation();

  const verizonImporting = useAppSelector(isVerizonImporting);
  const currentVerizonVehicle = useAppSelector(getCurrentVehicle);
  const totalVerizonVehicles = useAppSelector(getTotalVehicles);
  const currentVerizonVehicleIndex = useAppSelector(getCurrentVehicleIndex) || 0;

  const handleSubmit = async (values: Vehicle, helpers: FormikHelpers<Vehicle>) => {
    let submitedVehicle: Vehicle = { ...values };
    if (submitedVehicle.engineType !== EngineType.ELECTRIC) {
      submitedVehicle = { ...submitedVehicle, batteryCapacity: 0, listedRange: 0 };
    }

    if (!verizonImporting) {
      try {
        vehicle ? await editVehicle(submitedVehicle).unwrap() : await addVehicle(submitedVehicle).unwrap();
      } catch (error) {
        const apiErr = error as { data: apiErrors };
        if (apiErr && apiErr.data.errors) {
          helpers.setErrors(transformErrorFromApi(apiErr.data.errors));
        }
      }
    } else {
      dispatch(setEvrVehicle(submitedVehicle));
      if (totalVerizonVehicles > currentVerizonVehicleIndex + 1) {
        dispatch(closeDialog());
        dispatch(setVehicleIndex(currentVerizonVehicleIndex + 1));
        dispatch(setImportPosition(VerizonImportPosition.VEHICLE_IMPORT));
        setTimeout(() => {
          dispatch(openDialog({ type: Dialogs.EDIT_VEHICLE }));
        }, 200);
      } else {
        dispatch(closeDialog());
        dispatch(setImportPosition(VerizonImportPosition.DATA_IMPORT));
        setTimeout(() => {
          dispatch(openDialog({ type: Dialogs.VERIZON_IMPORT }));
        }, 200);
      }
    }
  };

  const isRangeRatioValid = (range: number | string, capacity: number | string, vehicleSize: VehicleSize): boolean => {
    if (
      range === 0 ||
      capacity === 0 ||
      typeof range === "string" ||
      typeof capacity === "string" ||
      isNaN(range) ||
      isNaN(capacity)
    ) {
      return true;
    }

    const lowerBound = vehicleLowerKwBounds[vehicleSize];
    const upperBound = vehicleUpperKwBounds[vehicleSize];
    const milesPerKw = range / capacity;

    return !(milesPerKw < lowerBound || milesPerKw > upperBound);
  };

  const getButtonTitle = (): string => {
    if (!verizonImporting) {
      return vehicle ? "Edit" : "Add";
    } else {
      return "Continue";
    }
  };

  const handleVerizonPrevious = () => {
    if (currentVerizonVehicleIndex == 0) {
      dispatch(closeDialog());
      dispatch(setImportPosition(VerizonImportPosition.VEHICLE_SELECT));
      setTimeout(() => {
        dispatch(openDialog({ type: Dialogs.VERIZON_VEHICLE_SELECT }));
      }, 200);
    } else {
      dispatch(closeDialog());
      dispatch(setVehicleIndex(currentVerizonVehicleIndex - 1));
      dispatch(setImportPosition(VerizonImportPosition.VEHICLE_IMPORT));
      setTimeout(() => {
        dispatch(openDialog({ type: Dialogs.EDIT_VEHICLE }));
      }, 200);
    }
  };

  let updatedVehicleValues = vehicle ? { ...vehicle } : { ...vehicleInitialValues };
  if (verizonImporting) {
    if (currentVerizonVehicle.evrVehicle) {
      updatedVehicleValues = { ...currentVerizonVehicle.evrVehicle };
    } else {
      updatedVehicleValues.licence = currentVerizonVehicle.vehicle.registration;
      updatedVehicleValues.make = currentVerizonVehicle.vehicle.make;
      updatedVehicleValues.model = currentVerizonVehicle.vehicle.model;
      updatedVehicleValues.engineType = currentVerizonVehicle.vehicle.engineType;
      updatedVehicleValues.vehicleSize = currentVerizonVehicle.vehicle.size;
    }
  }
  return (
    <>
      <DialogHeader
        title={
          !verizonImporting
            ? vehicle
              ? DialogTitle.EDIT_VEHICLE
              : DialogTitle.ADD_VEHICLE
            : "Import Vehicle - " + (currentVerizonVehicleIndex + 1) + "/" + totalVerizonVehicles
        }
        showClose={!verizonImporting}
      />
      <DialogBody>
        <Formik initialValues={updatedVehicleValues} validationSchema={VehicleValidationSchema} onSubmit={handleSubmit}>
          {({ values }) => {
            const isNotElectric = values[VehicleKeys.ENGINE_TYPE] !== EngineType.ELECTRIC;
            const rangeRatioValid =
              isNotElectric ||
              isRangeRatioValid(
                values[VehicleKeys.LISTED_RANGE],
                values[VehicleKeys.BATTERY_CAPACITY],
                values[VehicleKeys.SIZE],
              );
            return (
              <Form>
                <Flex wrap="wrap" align="baseline" justify="flex-start">
                  <GridItem xs={12} sm={6}>
                    <FormikSelect label="Vehicle Size" name={VehicleKeys.SIZE} items={vehicleSizeOptions} />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <FormikSelect label="Vehicle Type" name={VehicleKeys.ENGINE_TYPE} items={engineTypeOptions} />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <FormikTextInput label="Reg. No." name={VehicleKeys.LICENCE} disabled={verizonImporting} />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <FormikTextInput label="Model" name={VehicleKeys.MODEL} />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <FormikTextInput label="Make" name={VehicleKeys.MAKE} />
                  </GridItem>

                  <GridItem xs={12} sm={6}>
                    <FormikTextInput
                      label="Range (Miles)"
                      type="number"
                      name={VehicleKeys.LISTED_RANGE}
                      disabled={isNotElectric}
                      value={isNotElectric ? 0 : values[VehicleKeys.LISTED_RANGE]}
                      warning={!rangeRatioValid}
                      warningText={!rangeRatioValid ? "The range to capacity is outside of expected levels" : null}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <FormikSelect
                      label="Plug Type"
                      items={plugSetOptions}
                      name={VehicleKeys.PLUG_SET}
                      disabled={isNotElectric}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <FormikTextInput
                      label="Capacity (kWh)"
                      type="number"
                      name={VehicleKeys.BATTERY_CAPACITY}
                      disabled={isNotElectric}
                      value={isNotElectric ? 0 : values[VehicleKeys.BATTERY_CAPACITY]}
                      warning={!rangeRatioValid}
                      warningText={!rangeRatioValid ? "The range to capacity is outside of expected levels" : null}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <FormikTextInput label="Load (KG)" name={VehicleKeys.MAX_PAYLOAD_WEIGHT} type="number" />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <FormikTextInput label="Storage (m3)" name={VehicleKeys.STORAGE_SIZE} type="number" />
                  </GridItem>
                  {isNotElectric ? (
                  <GridItem
                    xs={16}
                    sm={10}
                    style={{
                      display: "flex",
                      marginLeft: "15px",
                      justifyContent: "flex-start",
                    }}
                  >
                    <label>
                      <Field
                        type="checkbox"
                        name={VehicleKeys.LEZ_COMPLIANT}
                        style={{
                          transform: "scale(1.5)",
                          cursor: "pointer",
                        }}
                      />
                      <span style={{ marginLeft: "10px" }}>Lez Compliant</span>
                    </label>
                  </GridItem>
                ) : (
                  <Field type="hidden" name={VehicleKeys.LEZ_COMPLIANT} value={true} />
                )}
                </Flex>
                {!rangeRatioValid && (
                  <RangeRatioWarning
                    listedRange={values[VehicleKeys.LISTED_RANGE]}
                    capacity={values[VehicleKeys.BATTERY_CAPACITY]}
                    vehicleSize={values[VehicleKeys.SIZE]}
                  />
                )}
                <DialogActionButtons
                  loading={addLoading || editLoading}
                  buttonTitle={getButtonTitle()}
                  variant={verizonImporting ? "contained" : "text"}
                  onCancelAction={verizonImporting ? handleVerizonPrevious : undefined}
                  cancelText={verizonImporting ? "Previous" : "Cancel"}
                />
              </Form>
            );
          }}
        </Formik>
      </DialogBody>
    </>
  );
};
