export interface OrgSettings {
  batteryBuffer: number;
  defaultJobDuration: number;
  defaultInitialStateOfCharge: number;
  driverMaxWaitingTime: number;
  prioritiseEVs: boolean;
  spreadWork: boolean;
  enableRecharge: boolean;
  monitorDriverInactivity: boolean;
  activityTimeout: number;
  batteryBufferDistance: number;
  viewLezs: boolean;
  avoidLezs: boolean;
  batteryBufferType: BatteryBufferType;
  petrolCostOverride: number | undefined;
  dieselCostOverride: number | undefined;
  electricityCostOverride: number | undefined;
  proofOfDeliveryRequirement: number;
  verizonLastImportDate: Date | undefined;
}

export enum BatteryBufferType {
  PERCENTAGE = "Percentage",
  MILES = "Miles",
  KILOMETERS = "Kilometers",
}

export const batteryBufferOptions = Object.entries(BatteryBufferType).map(([key, value]) => ({ key, value }));
