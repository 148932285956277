import { evrApi } from "@evr/apis";
import { ApiMethodKeys, CommonKeys, notificationsMsg, SnackbarKeys, WindowKeys } from "@evr/constant";
import { closeDialog } from "@evr/store/slices/dialog";
import { pushSnackbar } from "@evr/store/slices/snackbar";
import { openWindow } from "@evr/store/slices/window";
import { ApiPageResponse, Lez} from "@evr/types";

import { CacheKeys } from "../cacheKeys";
import { LezEndpoint } from "../endpoints";

export const lezApi = evrApi.enhanceEndpoints({ addTagTypes: [CacheKeys.LEZS] }).injectEndpoints({
  endpoints: builder => ({
    lezs: builder.query<ApiPageResponse<Lez>, string>({
      query: query => ({
        url: `${LezEndpoint}?${query}`,
      }),
      providesTags: [CacheKeys.LEZS],
    }),
    addLez: builder.mutation<Lez, Lez>({
      query: data => ({ url: LezEndpoint, method: ApiMethodKeys.POST, data }),
      invalidatesTags: [CacheKeys.LEZS],
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(pushSnackbar({ type: SnackbarKeys.SUCCESS, title: notificationsMsg.ADD_ITEM(CommonKeys.LEZ) }));
          dispatch(closeDialog());
        });
      },
    }),
    editLez: builder.mutation<Lez, Lez>({
      query: data => ({ url: LezEndpoint, method: ApiMethodKeys.PATCH, data }),
      invalidatesTags: [CacheKeys.LEZS],
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(pushSnackbar({ type: SnackbarKeys.SUCCESS, title: notificationsMsg.EDIT_ITEM(CommonKeys.LEZ) }));
          dispatch(closeDialog());
          dispatch(openWindow(WindowKeys.LEZ));
        });
      },
    }),
    deleteLez: builder.mutation<void, number>({
      query: id => ({ url: `${LezEndpoint}/${id}`, method: ApiMethodKeys.DELETE }),
      invalidatesTags: [CacheKeys.LEZS],
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(pushSnackbar({ type: SnackbarKeys.SUCCESS, title: notificationsMsg.DELETE_ITEM(CommonKeys.LEZ) }));
          dispatch(closeDialog());
        });
      },
    }),
  }),
});

export const { useLezsQuery, useAddLezMutation, useEditLezMutation, useDeleteLezMutation } = lezApi;
