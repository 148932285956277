import { LezKeys, OperationDaysOptions } from "@evr/constant";
import { SearchFieldKeys, TableColumn } from "@evr/types";

export const lezTableColumns: TableColumn<LezKeys>[] = [
  {
    name: "Name",
    field: LezKeys.NAME,
    sortable: true,
    search: { type: SearchFieldKeys.TEXT },
  },
  {
    name: "Country",
    field: LezKeys.COUNTRY,
  },
  {
    name: "City",
    field: LezKeys.CITY,
  },
  {
    name: "Cost",
    field: LezKeys.COST,
  },
  {
    name: "Operation days",
    field: LezKeys.OPERATION_DAYS,
    sortable: true,
    search: {type: SearchFieldKeys.SELECT, options: OperationDaysOptions  },
  },
  {
    name: "Start time",
    field: LezKeys.START_HOUR,
  },
  {
    name: "end time",
    field: LezKeys.END_HOUR,
  },
  {
    name: "Effective start",
    field: LezKeys.EFFECTIVE_START,
  },
  {
    name: "Effective end",
    field: LezKeys.EFFECTIVE_END,
  },
  {
    name: "Active",
    field: LezKeys.IS_ACTIVE,
  }
];
