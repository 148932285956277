export enum SliceKeys {
  SNACKBAR = "snackbar",
  DEPOTS = "depots",
  DILAOG = "dialog",
  WINDOW = "window",
  DRIVERS = "drivers",
  VEHICLES = "vehicles",
  DV_MATCH = "dvMatch",
  PARCEL = "parcel",
  SETTINGS = "settings",
  AUTH = "auth",
  RESULT = "result",
  CALCULATION = "calcualtion",
  SIDEBAR = "sidebar",
  SCHEDULED_JOURNEYS = "scheduledJourneys",
  SCHEDULED_JOURNET_RESULT = "scheduledJourneyResult",
  ALL_LIVE_JOURNEYS_RESULT = "allLiveJourneysResult",
  MAP = "map",
  VERIZON = "verizon",
  LEZS = "lezs",
  COUNTRIES = "countries"
}
